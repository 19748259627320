import React, { Component } from 'react';
import { useParams, useNavigate  } from 'react-router-dom';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth } from "../Helpers/SettingHelper";
import { apiUrl,specURL} from'../Config/Config';
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';

const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
	
	return (
	  <WrappedComponent
	  {...props}
	  params={params}
	  navigate ={navigate}
	  // etc...
	  />
	);
	};

class Edit extends Component {

	constructor(props)
	{
		CheckAuth();
		super(props);	
		const tabId = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;

		this.state = {
            tabId: tabId,
			tab_name:'',
			description:'',
			title:'',
			content:'',
			Loading:false,
			selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'',
			selectedtype : '',
			selectedtypeValue : '',
			editid:0,
			image_preview:'',
			image_name:'',
			image:'',
			tabFolder:'',
			old_image:'',
			fileExtension:'',
			showCategoryDropdown:false,
			category_id:'',
			categorynamelist: [],
			selectedCategory:'',
		};
		// this.props.getCategoryDetailList(tabId);
		var qs = require('qs');
		var catid = this.state.tabId;
		var postObject = {
			raisedispute_id : this.state.tabId,
			admin_id : localStorage.getItem("admin_id"),
		};
		axios.post(apiUrl+'raisedispute/listdetail',qs.stringify(postObject))
		.then(res => {
			this.setState({tab_name:res.data.specialtiesDetails.name, category_id:res.data.specialtiesDetails.id});
			this.setState({ selectedCategory : res.data.specialtiesDetails})
			
			if(res.data.specialtiesDetails.status === 'A'){
				this.setState({selectedOption:{value: 'active', label: 'Active'}});
			}else{
			this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});
			}
			// if(res.data.tablist.content_type !=''){
			// 	this.setState({selectedtype:{value: res.data.tablist.content_type, label: lang.Capitalize(res.data.tablist.content_type)}});
			// }
			this.setState({image_name:res.data.specialtiesDetails.image, editid:1,tabFolder:res.data.tabfolder,old_image:res.data.tablist.image})
		}); 
		
		this.handleInputChange = this.handleInputChange.bind(this);
	    this.handleChange = this.handleChange.bind(this);

    }
	
    handleChange = selectedOption => {

        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
   handleInputChange(event) {
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
      if(name === 'tab_trending'){
      	this.setState({tab_trending_checked: !this.state.tab_trending_checked});
      }  		
    }

	componentDidMount() {
		document.title = PageTitle('Tab Edit');
		var admin_id = localStorage.getItem('admin_id');
			
    }

	onChangeHandler=event=>{
		let reader = new FileReader();
		const file = event.target.files[0];
		reader.onloadend = () => {
		this.setState({
			image_name: file.name,
			image_preview: reader.result,
			image: file
		});
		
		};
		reader.readAsDataURL(file);
	}
        handleFormSubmit = () => {
			if(this.validateForm()){ 
				this.setState({Loading:true});
				
				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}

				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					spc_name: formPayload.tab_name,
					status:  status,
					//image: formPayload.image,
					id: formPayload.tabId,
				};
				
				//console.log(postObject,'tabeditmanege')
				let formData = new FormData();
				for(let k in postObject) {
				formData.append(k, postObject[k]);
				}		       
				axios.post(apiUrl+"raisedispute/edit",formData ).then(res => {
					if(res.data.status === "success"){	
						this.setState({Loading:false});
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
								setTimeout(
								function() {
									$('.success_message').html('');
                                    this.props.navigate('/raisedispute');
								}
								.bind(this),
								3000
								);
                               
					}else{
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
						
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}
				});
			}
	}

	validateForm() {
		//const {admin_company_name,admin_fname} = this.state;
		
		const {tab_name,title,description,selectedtypeValue,image} = this.state;
		let errors = {};
      	let formIsValid = true;
		
		if (!tab_name) {
			formIsValid = false;
			$('.errortab_name').html('<span class="errorspan">Please fill the field</span>');
		}
		
		
		
		this.setState({
			errors: errors
		});
		return formIsValid;
    }
	handleClickSelecetCategory = (event) => {
		const newCategoryId = event.target.value;
		console.log(newCategoryId,'new');
		this.setState({ category_id: newCategoryId });
	};
	
	handleChangeCategory = selectedCategory => {

		this.setState({ selectedCategory});
		this.setState({ categoryvalue : selectedCategory.value });
 	}

  render() {

	const navigate = this.props.navigate;
  	const {selectedOption,selectedtype,tabFolder ,categorynamelist} = this.state;

    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="raisedispute" />  
      <div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
				<div className="back-btn-lux">
					<a onClick={() => navigate(-1)}>Back</a>    
					</div>
			<div className="title">
				<h4>Edit Details</h4>
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} encType="multipart/form-data">
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label> Name<span class="required" style={{ color: "red" }} >* </span> : </label>
				    <input type="text" name="tab_name" onChange={this.handleInputChange} className="form-control" value={this.state.tab_name} />
					<div className="errortab_name"></div>
				</div>
				{/* <div className="form-group">
					<label>Image:</label>
					<div className="choose-file">
					{this.state.isHidden && <div className="image_success"></div>}
					<span className="profile_btn"><input type="file" name="file" onChange={this.onChangeHandler} /></span>
					</div>
					{!this.state.image_preview && (
					<div className="form-group">
						{preview}
						{(this.state.image_name !== null && this.state.image_name !== '') && (
						<a href="javascript:void(0);" onClick={this.imageRemove.bind(this, this.state.category_id)}>X</a>
						)}
					</div>
					)}
					<div className="errorloaderimage"></div> 
					{this.state.image_preview && (
						<div className="image-preview">
						<img src={this.state.image_preview} alt="Preview" className='img_class' />
						<a href="javascript:void(0);" onClick={this.imageRemove.bind(this, this.state.category_id)}>X</a>
						</div>
					)} 
				</div> */}
				
			</div>
                  
			<div className="form-right">
			
			
				{/* <div className="form-group">					
					<label>Description<span class="required" style={{ color: "red" }} >* </span> : </label>
						<textarea type="text" name="description" onChange={this.handleInputChange} className="form-control" value={this.state.description}  />
					<div className="errordescription"></div>
				</div> */}
				<div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} >* </span> :</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
	           </div> 
				


			</div>	
			</div>		



			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true?
                                        <span>updating</span>:<span>Submit</span>
                                        }
                   </button>
			</div>
			</form>
			</div>				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


// const mapStateTopProps = (state) => {
//   return {
//     categorydetails: state.categorydetails,
//     categoryedit: state.categoryedit
//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//     getCategoryDetailList: (tabId) =>{
//        dispatch({ type: GET_CATEGORYDETAILLIST,tabId });
//     },
//     getEditCategory: (formPayload) =>{
//        dispatch({ type: GET_EDITCATEGORY, formPayload});
//     }
//   }
// }
export default (withRouter(Edit));